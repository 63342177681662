import React, { useState } from 'react'
import { Typography, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableFooter, TablePagination, Paper, CircularProgress, IconButton, Collapse } from '@mui/material'
import StyledChip from './StyledChip'
import CheckIcon from '@mui/icons-material/Check'
import ErrorIcon from '@mui/icons-material/Error'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import useResults from '../hooks/useResults'
import Loading from './Loading'
import Error from '../views/misc/Error'
import Output from './Output'
import _ from 'lodash'

const StatusChip = ({status}) => {
  return <>{ status == 'SUCCESS' ? 
  <StyledChip label={_.capitalize(status)} color="green" icon={<CheckIcon/>}/> : 
  <StyledChip label={_.capitalize(status)} color="red" icon={<ErrorIcon/>}/>}</>
}

function Row({row}) {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'none' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.data.createdOn.toDate().toLocaleString()}
        </TableCell>
        <TableCell align="left">{row.data.name}</TableCell>
        <TableCell align="center"><StatusChip status={row.data.status} /></TableCell>
        <TableCell align="right">{row.data.executionTime / 100}s</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              {console.log(row.data)}
              <Output data={
                JSON.parse(row.data.dataString).scrapedData ? 
                JSON.parse(row.data.dataString).scrapedData : 
                JSON.parse(row.data.dataString).error ? 
                JSON.parse(row.data.dataString).error : 
                JSON.parse(row.data.dataString).code ?
                JSON.parse(row.data.dataString).code : {}
              }/>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

const ResultsTable = ({deploymentId}) => {


  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [ resultsError, resultsLoading, results, previousPage, nextPage ] = useResults(rowsPerPage, deploymentId)
  
  const handleChangePage = (event, newPage) => {
    if (newPage > page) {
      nextPage()
      setPage(page + 1)
    } else {
      previousPage()
      setPage(page - 1)
    }
    ;
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (resultsLoading) return (<Paper sx={{ borderWidth: '1px', boxShadow: 'none' }}><Loading/></Paper>)
  if (resultsError) return (<Paper sx={{ borderWidth: '1px', boxShadow: 'none' }}><Error/></Paper>)

  return (
    <Box>
      <TableContainer component={Paper} sx={{ borderWidth: '1px', boxShadow: 'none', borderRadius: 2 }}>
        <Table sx={{ minWidth: 650 }} aria-label="collapsable table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Date</TableCell>
              <TableCell>Name</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="right">Execution Time</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!resultsLoading && results.data.map(item => {return{data: item.data(), id: item.id}}).map((result) => (
              <Row key={result.id} row={result} />
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50, 100, { label: 'All', value: -1 }]}
                colSpan={5}
                count={results ? results.count : 0}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page',
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default ResultsTable
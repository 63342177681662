import React, { useState, useEffect } from 'react'
import { TextField, Stack, Card, Button } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'

const VariableListItem = ({name, value, onChange, disabled}) => {
  const [ varName, setVarName ] = useState(name)
  const [ varValue, setVarValue ] = useState(value)

  useEffect(() => {
    onChange({name: _.camelCase(varName), value: varValue})
    return
  }, [varName, varValue])
  
  return (
    <>
    {/* <ListItem secondaryAction={<IconButton><DeleteIcon/></IconButton>}> */}
    <Card elevation={0} p={1} mt={1} sx={{border: '1px solid lightgray'}}>
        <Stack p={2} gap={2} >
            <TextField disabled={disabled} fullWidth label="Name" value={name} InputLabelProps={{ shrink: true }} onChange={event => setVarName(event.target.value)}/>
            <TextField disabled={disabled} fullWidth label="Value" value={value} InputLabelProps={{ shrink: true }} onChange={event => setVarValue(event.target.value)}/>
            { !disabled && <Button size='small' variant='contained' color={'error'} onClick={() => onChange()}>Remove</Button> }
        </Stack>
    </Card>
    <br/>
    {/* </ListItem> */}
    </>
  )
}

export default VariableListItem
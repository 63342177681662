import Firebase from 'firebase/compat/app'
import ReactGA from 'react-ga'

import { prepareDocForCreate } from './helpers/firestoreHelpers'

const likeScript = script => {

  ReactGA.event({
    category: 'Script',
    action: 'Like script',
  })

  const like = prepareDocForCreate({
    scriptId: script.id,
  })

  return Firebase.firestore().collection('scriptLikes').add(like)
}

export default likeScript

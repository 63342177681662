import React from 'react'
import { CircularProgress, Box } from '@mui/material'

const Loading = () => {
  return (
    <Box sx={{ alignContent: 'center', justifyContent: 'center', display: 'grid', minHeight: '400px', marginLeft: 'auto'}}>
        <CircularProgress/>
    </Box>
  )
}

export default Loading
import React from 'react'
import { FirestoreCollection } from 'react-firestore'
import { Typography, Box, Button } from '@mui/material'
import FavoriteIcon from '@mui/icons-material/Favorite'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'

import likeScript from '../../actions/likeScript'
import unlikeScript from '../../actions/unlikeScript'
import FirebaseAuth from '../../views/misc/FirebaseAuth'
import updateScript from '../../actions/updateScript'

const LikeCount = ({ count, children }) => {
    return (
        <>
            <Box display={'flex'}>
                <Typography>
                    {count}
                </Typography>
                {children}
            </Box>
        </>
    )
}

const LikeScriptButton = ({script, count = true, variant, sx}) => {
  return (
    <FirebaseAuth>
    { ({isLoading, error, auth}) => {

      if (!auth || isLoading || error) {
        return <Button sx={sx} variant={variant} disabled><FavoriteBorderIcon/></Button>
      }

      return <FirestoreCollection
        path={'scriptLikes'}
        filter={[
          ['scriptId', '==', script.id],
          ['createdBy', '==', auth.uid],
        ]}
      >
        { ({error, isLoading, data}) => {

          if (error || isLoading) {
            return <Button sx={sx} variant={variant} disabled><FavoriteBorderIcon/></Button>
          }

          const userLike = data[0]

          return <Button sx={sx} variant={variant} onClick={ () => {
            if (userLike) {
              unlikeScript(userLike)
            //   updateScript(script.id, {_likeCount: 10})
            } else {
              likeScript(script)
            //   updateScript(script.id, {_likeCount: 10})
            }
          }} startIcon={userLike ? <LikeCount count={script._likeCount}/> : <LikeCount count={script._likeCount}/>}>
          {userLike ? <FavoriteIcon/> : <FavoriteBorderIcon/>}
          </Button>
        }}
      </FirestoreCollection>
    }}
  </FirebaseAuth>
)
}

export default LikeScriptButton
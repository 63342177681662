import LogoutIcon from '@mui/icons-material/Logout';
import { Avatar, CardContent, Card, Typography, Button, Divider, CardHeader, Box } from '@mui/material';
import React from 'react'
import { useNavigate } from 'react-router-dom'

import logOut from '../../actions/logOut'

const Profile = ({auth}) => {
  let navigate = useNavigate();
   return (
        // <div>
        //   <img src={auth.photoURL} alt={auth.displayName} width="100" height="100" />
        //   <p><strong>{auth.displayName}</strong></p>
        //   <p>{auth.email}</p>
        //   <button onClick={() => logOut().then( () => navigate(`/`, { replace: true })) }>log out</button>
        // </div>
        <Card sx={{maxWidth: '450px', mx: 'auto'}}>
          <CardHeader title='Account'/>
          <Divider/>
          <CardContent>
            <Box mb={2} display='flex'>
              <Avatar sx={{ width: '60px', height: '60px' }} alt={auth.displayName} src={auth.photoURL}/>
              <Box ml={2}>
                <Typography variant="h6">{auth.displayName}</Typography>
                <Typography color={'gray'}>{auth.email}</Typography>
              </Box>
            </Box>
            <Button fullWidth color='error' variant="outlined" endIcon={<LogoutIcon/>} onClick={() => logOut().then( () => navigate(`/`, { replace: true })) }>log out</Button>
          </CardContent>
        </Card>
    )
}
  

export default Profile

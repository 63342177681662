import Firebase from 'firebase/compat/app'
import ReactGA from 'react-ga'

const unlikeScript = userLike => {

  ReactGA.event({
    category: 'Script',
    action: 'Unlike script',
  })

  return Firebase.firestore()
    .collection('scriptLikes')
    .doc(userLike.id)
    .delete()
}

export default unlikeScript

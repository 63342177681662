import Firebase from 'firebase/compat/app'
import ReactGA from 'react-ga'

const deleteScript = id => {

  ReactGA.event({
    category: 'Script',
    action: 'Delete script',
  })

  return Firebase.firestore()
    .collection('scripts')
    .doc(id)
    .delete()
    // .catch( error => {
    //   alert(`Whoops, couldn't delete the post: ${error.message}`)
    // })
}

export default deleteScript

import React, { useState } from 'react'
import { useParams, useOutletContext, Link, useNavigate } from 'react-router-dom'
import { Box, CardContent, Card, Typography, Button, Stack, List, ListItem, ListItemText, Divider, CircularProgress, Chip } from '@mui/material'
import { FirestoreDocument } from 'react-firestore'
import DeleteIcon from '@mui/icons-material/Delete'
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft'
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined'
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined'
import CloudSyncOutlinedIcon from '@mui/icons-material/CloudSyncOutlined'
import CloudOffIcon from '@mui/icons-material/CloudOff'
import Papa from 'papaparse'

import Error from '../misc/Error'
import deleteDeployment from '../../actions/deleteDeployment'
import downloadResults from '../../actions/downloadResults'
import Loading from '../../components/Loading'
import ResultsTable from '../../components/ResultsTable'
import StyledChip from '../../components/StyledChip'
import DownloadIcon from '@mui/icons-material/Download'
import Scheduler from '../../components/Scheduler'

const DeploymentHeader = ({ data, auth }) => {
    const navigate = useNavigate()
    const removeDeployment = async () => {
        await deleteDeployment(data.id)
        navigate(`../../deployed`)
    }
    return (
        <Box display="flex" justifyContent={'space-between'}>
            <Box mb={1}>
                <Typography variant="h4">{ data.name }</Typography>
                {/* <Typography variant='subtitle1'>{ data.description }</Typography> */}
            </Box>
            <Box >
                {/* { data.createdBy == auth.currentUser.uid ? <Button variant='outlined' color='secondary' sx={{mr: 2}} component={Link} to={'./edit'}>
                    Edit
                </Button> : null} */}
                <Button startIcon={<DeleteIcon/>} color="error" variant='outlined' onClick={() => removeDeployment()}>
                    Delete
                </Button>
            </Box>
        </Box>
    )
}

const ViewDeployment = () => {
  const { auth } = useOutletContext()
  let { deploymentId } = useParams()
  const [ downloadLoading, setDownloadLoading ] = useState()

  const downloadDeploymentResults = async () => {
    setDownloadLoading(true)
    const results = await downloadResults(deploymentId)
    const resultData = results.map(result => {
        return {status: result.status, scrapedData: JSON.parse(result.dataString).scrapedData, executionTime: result.executionTime}
    })
    const csvData = Papa.unparse(resultData)
    const element = document.createElement('a');
    const file = new Blob([csvData], {
        type: "text/csv"
      });
    element.href = URL.createObjectURL(file);
    element.download = "myFile.csv";
    document.body.appendChild(element);
    element.click();
    setDownloadLoading(false)
  }
  return (
    
    // <FirebaseAuth>
        <FirestoreDocument
            path={`deployed/${deploymentId}`}
        >
            { ({error, isLoading, data}) => {

            if (isLoading) {
                return <Loading/>
            }
            if (error) return <Error/>
            if (data.createdBy != auth.currentUser.uid) {
                return <Error/>
            }
            const { amount, unit } = JSON.parse(data.schedule)

            return (
                <Box>
                    <DeploymentHeader data={{id: deploymentId, ...data}} auth={auth}/>
                    <Stack gap={2}>
                        <Stack>
                            <Card>
                                <CardContent>
                                    <Stack justifyContent={'space-between'} direction={'row'}>
                                        {/* <Box>
                                            <Typography variant="subtitle1">Description</Typography>
                                            <Typography>{data.description}</Typography>
                                            <Typography variant="subtitle1">First Deployed</Typography>
                                            <Typography>{data.createdOn.toDate().toLocaleString()}</Typography>
                                        </Box>
                                        
                                        <Box>
                                            <Typography variant="subtitle1">Runs</Typography>
                                            <Typography>2557</Typography>
                                            <Typography variant="subtitle1">Next run</Typography>
                                            <Typography>{data.nextRun.toDate().toLocaleString()}</Typography>
                                            <StyledChip icon={data.deployed ? <CloudUploadOutlinedIcon/> : <CloudOffIcon/>} color={data.deployed ? 'green' : 'red'} label={data.deployed ? 'Depolyed' : 'Paused' } />
                                            <StyledChip color={'blue'} icon={<TimerOutlinedIcon/>} label={`${amount} ${unit}`}/>
                                            {
                                            data.deployed ? <StyledChip color={'purple'} icon={<CloudSyncOutlinedIcon/>} label={data.nextRun.toDate().toLocaleString()}/> : <Chip icon={<CloudSyncOutlinedIcon/>} label='Not Applicable'/>
                                            }
                                        </Box> */}
                                        <Stack gap={1} direction={'row'}>
                                            <Button variant='outlined' component={Link} to={`/script/${data.scriptId}/edit`} startIcon={<ArrowLeftIcon/>}>View Script</Button>
                                            <Button variant='contained' startIcon={<DownloadIcon/>} onClick={() => downloadDeploymentResults()}>{downloadLoading ? <CircularProgress color='secondary' size={24}/> : 'Download CSV'}</Button>
                                        </Stack>
                                        <Stack gap={1} direction={'row'}>
                                            <StyledChip icon={data.deployed ? <CloudUploadOutlinedIcon/> : <CloudOffIcon/>} color={data.deployed ? 'green' : 'red'} label={data.deployed ? 'Depolyed' : 'Paused' } />
                                            <StyledChip color={'blue'} icon={<TimerOutlinedIcon/>} label={`${amount} ${unit}`}/>
                                            {
                                            data.deployed ? <StyledChip color={'purple'} icon={<CloudSyncOutlinedIcon/>} label={data.nextRun.toDate().toLocaleString()}/> : <Chip icon={<CloudSyncOutlinedIcon/>} label='Not Applicable'/>
                                            }
                                        </Stack>
                                    </Stack>
                                    <Stack>

                                    </Stack>
                                </CardContent>
                            </Card>
                        </Stack>
                        <Typography variant="h4">Results</Typography>
                        <ResultsTable deploymentId={deploymentId}/>
                    </Stack>
                </Box>
            )
                // <Box>
                //     <DeploymentHeader data={{id: deploymentId, ...data}} auth={auth}/>
                //     <Box sx={{ display: 'flex', gap: 2 }}>
                //         <ResultsTable/>
                //         <Stack minWidth={350} gap={2}>
                //             <Card>
                //                 <CardContent>
                //                     <Typography variant="subtitle1">Script Title</Typography>
                //                     <Typography>{data.name}</Typography>
                //                     <Typography variant="subtitle1">Script Description</Typography>
                //                     <Typography>{data.description}</Typography>
                //                     <Typography variant="subtitle1">First Deployed</Typography>
                //                     <Typography>{data.createdOn.toDate().toLocaleString()}</Typography>
                //                     {/* <VariableList initialData={JSON.parse(data.variables)} disabled variablesChanged={() => null}/> */}
                //                 </CardContent>
                //             </Card>
                //         </Stack>
                //     </Box>
                // </Box>)
            }}
        </FirestoreDocument>
    // </FirebaseAuth>
  )
}

export default ViewDeployment
import React from 'react'
import { Box, Typography, Grid } from '@mui/material'
import ScriptItem from '../ScriptItem'

const ScriptGrid = ({ scripts, title, toEdit }) => {
  return (
    <Box>
        <Typography variant='h4'>{title}</Typography>
        <Grid mt={0} container spacing={3}>
            {scripts.map(script => (
            <Grid item xs={4} zeroMinWidth>
                <ScriptItem 
                    link={`/script/${script.id}${toEdit ? '/edit' : ''}`} 
                    key={script.id} 
                    name={script.name} 
                    description={script.description} 
                    likes={script._likeCount}
                    code={script.code}
                    published={script.published}/>
            </Grid>
            ))}
        </Grid>
    </Box>
  )
}

export default ScriptGrid
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/functions'
import 'firebase/compat/firestore'
import { getFunctions } from 'firebase/functions'

// connect our app to firebase 
const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  }

// Firebase.initializeApp(dbConfig)
const app = firebase.initializeApp(firebaseConfig)

getFunctions(app)

console.log(window.location.hostname)
if (window.location.hostname === "localhost") {
  app.functions().useEmulator("localhost", 5000);
  // app.auth().useEmulator("http://localhost:9099");
  // app.firestore().settings({
  //   host: "localhost:8080",
  //   ssl: false,
  // })
}

export { app }
import React, { useState } from 'react'
import { Typography, TextField } from '@mui/material';


const EditableText = ({ size, onChange, variant, fullWidth, value }) => {
    // const [name, setName] = useState("John Snow");
    const [isNameFocused, setIsNamedFocused] = useState(false);
  
    // ((note)) ref doesn't work as TextField doesn't exist when running Typography's onClick
    // console.log({ isNameFocused });
  
    // ((todo)) create EditableField component
    // ((todo)) put cursor where user clicks rather than at the end
    return (
      <div className="App">
        {!isNameFocused ? (
          <Typography
            // className={classes.name}
            variant={variant}
            onClick={() => {
              setIsNamedFocused(true);
            }}
          >
            {value == '' ? 'Add description' : value}
          </Typography>
        ) : (
          <TextField
            multiline
            size={size}
            autoFocus
            // inputProps={{ className: classes.name }}
            value={value}
            fullWidth={fullWidth}
            variant="standard"
            onChange={event => {onChange(event.target.value)}}
            onBlur={event => setIsNamedFocused(false)}
          />
        )}
      </div>
    );
}

export default EditableText
import Firebase from 'firebase/compat/app'
import ReactGA from 'react-ga'
import { prepareDocForUpdate } from './helpers/firestoreHelpers'

const updateDeployed = (deployedId, values) => {

  ReactGA.event({
    category: 'Deployed',
    action: 'Update deployed',
  })

  return Firebase.firestore()
    .collection('deployed')
    .doc(deployedId)
    .update(prepareDocForUpdate(values))
    .catch( error => {
      alert(`Whoops, couldn't edit the post: ${error.message}`)
    })
}

export default updateDeployed

import React from 'react'
import { Typography, Box, Grid } from '@mui/material'
import ScriptGrid from '../../components/ScriptGrid'
import { FirestoreCollection } from 'react-firestore'
import FirebaseAuth from '../misc/FirebaseAuth'
import Loading from '../../components/Loading'
import Error from '../misc/Error'
import DeployedList from './DeployedList'

const Deployed = () => {
  return (<FirebaseAuth>
  { ({isLoading, error, auth}) => {

    if (!auth || isLoading || error) {
      return <Error/>
    }

    return <FirestoreCollection
      path={'deployed'}
      // sort="createdOn:desc"
      filter={['createdBy', '==', auth.uid]}
    >
      { ({error, isLoading, data}) => {

        if (error) {
          return <Error error={error} />
        }

        if (isLoading) {
          return <Loading/>
        }

        if (data.length === 0) {
          return <Typography variant='h4'>No posts yet!</Typography>
        }

        return <DeployedList deployed={data} title={'Deployed scripts'} />
      }}
    </FirestoreCollection>
  }}</FirebaseAuth>
    )
}

export default Deployed
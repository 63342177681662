import React from 'react'
import FirebaseAuth from './FirebaseAuth'
import AuthPortal from '../AuthProtal'
import Loading from '../../components/Loading'

const RequireAuth = ({ children }) => {
  return (<FirebaseAuth>
        { ({isLoading, error, auth}) => {
          if (isLoading) {
            return <Loading/>
          }
          if (error) {
            return '⚠️ login error'
          }
          if (auth) {
            return children
          } else {
            return <AuthPortal/>
          }
        }}
      </FirebaseAuth>)
}

export default RequireAuth
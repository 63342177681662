import React, { useState } from 'react'
import { Box, Divider, IconButton, List, ListItem, ListItemText, TextField, Typography } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import AddIcon from '@mui/icons-material/Add'
import VariableListItem from './VariableListItem'

const VariableList = ({initialData, variablesChanged, disabled}) => {
//   const [ data, setData ] = useState(initialData)

  const addVariable = () => {
    if (initialData?.filter(i => (i.name == '' || i.value == '')).length > 0) return
    // setData([...data, {name: '', value: ''}])
    variablesChanged([...initialData, {name:'', value: ''}])
  }
  const updateVariables = (variable, index) => {
    const newVariables = initialData
    variable == null ?  newVariables.splice(index, 1) : newVariables[index] = variable
    // newVariables[index] = variable
    variablesChanged(newVariables)
  }
  return (
    <>
    <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
        <Typography variant='h6'>Variables</Typography>
        { !disabled && <IconButton sx={{mb:1}} onClick={() => addVariable()}>
            <AddIcon/>
        </IconButton> }
    </Box>
    <Box sx={{ overflow:'scroll', maxHeight: '450px' }} >
        { !disabled && initialData.length < 1 && <><Typography color={'darkgrey'} variant='h6'>Add a variable</Typography><Typography color={"gray"}>It will become available for use in your script.</Typography></> }
        { disabled && initialData.length < 1 && <Typography sx={{mt: 2}} color={'darkgrey'} variant='h6'>No variables</Typography> }
        { initialData.map((i, index) => <VariableListItem onChange={variable => updateVariables(variable, index)} {...i} disabled={disabled}/>) }
    </Box>
    </>
  )
}

export default VariableList
import React from 'react'
import { Box, Button, Card, CardContent, Divider, Typography } from '@mui/material'
import GoogleIcon from '@mui/icons-material/Google'
import logIn from '../../actions/logIn'

const AuthContainer = ({children}) => {
    const styles = {
        // background: 'linear-gradient(90deg, #cfecd0, #ffc5ca)', 
        background: 'linear-gradient(90deg, #C33764, #1D2671)',
        minHeight: '100vh', 
        width: '100%',
        paddingTop: 10
    }
    return (<Box sx={styles}>
        {children}
    </Box>)
}

const AuthCard = () => {
    return (
        <Card sx={{marginLeft: 'auto', marginRight: 'auto', maxWidth: '450px'}}>
            <CardContent sx={{ py: 6 }}>
                <Box mb={6}>
                    <Typography sx={{ textAlign: 'center' }} variant="h6">Welcome to Browser Script</Typography>
                    <Typography sx={{ textAlign: 'center', color: 'gray' }} variant="subtitle1">Sign in to get started</Typography>
                </Box>
                 <Divider/>
                 <Box mt={6} mx={4}>
                    <Button startIcon={<GoogleIcon/>} variant='contained' fullWidth onClick={logIn}>Sign in with Google</Button>
                 </Box>
            </CardContent>
        </Card>
    )
}

const AuthPortal = () => {
  return (
    <AuthContainer>
        <AuthCard/>
    </AuthContainer>
  )
}

export default AuthPortal
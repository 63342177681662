import React, { useState, useEffect } from 'react'
import { useParams, useOutletContext, Link, useNavigate } from 'react-router-dom'
import { CircularProgress, Typography, Box, Button, Card, CardContent, TextField, Divider, Stack, Switch, FormControlLabel, Chip, ListItem, ListItemText, ListItemIcon } from '@mui/material'

import { FirestoreDocument } from 'react-firestore';

import AddIcon from '@mui/icons-material/Add'
import PublicIcon from '@mui/icons-material/Public'
import PublicOffIcon from '@mui/icons-material/PublicOff'
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete'
import CodeEditor from '../../components/CodeEditor'
import VariableList from '../../components/VariableList';
import Output from '../../components/Output';
import Loading from '../../components/Loading';
import EditableText from '../../components/EditableText';
import LikeScriptButton from '../../components/LikeScriptButton';
import Scheduler from '../../components/Scheduler';
import Error from '../misc/Error';
import FirebaseAuth from '../misc/FirebaseAuth';
import deleteScript from '../../actions/deleteScript';
import executeTestScript from '../../actions/executeTestScript';
import updateScript from '../../actions/updateScript';
import deployScript from '../../actions/deployScript'



const EditScript = () => {

  const [ code, setCode ] = useState(null)
  const [ loading, setLoading ] = useState(false)
  const [ testLoading, setTestLoading ] = useState(false)
  const [ output, setOutput ] = useState(null)
  let { scriptId } = useParams()
  const navigate = useNavigate()

  const codeUpdated = async (code) => {
    const res = await updateScript(scriptId, {code: code})
  }
  useEffect(() => {
    
    const delayDebounceFn = setTimeout(async () => {
      if (code == null) return
      setLoading(true)
      await codeUpdated(code)
      setLoading(false)
    }, 3000)
    return () => clearTimeout(delayDebounceFn)
  }, [code])

  const handleClick = async (baseUrl, variables) => {
    setTestLoading(true)
    const args = variables.reduce((obj, item) => (obj[item.name] = item.value, obj) ,{})
    const returnedOutput = await executeTestScript(baseUrl, code, args)
    setTestLoading(false)
    setOutput(returnedOutput)
  }
  const updateVariables = async (variables) => {
    await updateScript(scriptId, {variables: JSON.stringify(variables)})
  }
  const updateName = async (name) => {
    await updateScript(scriptId, {name: name})
  }
  const updateUrl = async (url) => {
    await updateScript(scriptId, { baseUrl: url })
  }
  const updateDescription = async (description) => {
    await updateScript(scriptId, {description: description})
  }
  const updatePublish = async (bool) => {
    await updateScript(scriptId, { published: bool })
  }
  const removeScript = async () => {
    await deleteScript(scriptId)
    navigate('../../scripts')
  }
  const { auth } = useOutletContext()

  return (
    <FirestoreDocument
        path={`scripts/${scriptId}`}
    >
        {({ isLoading, data }) => {
            if (isLoading) return (
                <Loading/>
            ) 
            if (auth.currentUser.uid != data.createdBy) return <Error/>
            else {
            return (
            <>
            <Box pb={1} display="flex" justifyContent={'space-between'}>
                <Typography variant='h4'>{data.name}</Typography>
                <Box>
                    <Button sx={{ mr: 2 }} to={`../${data.id}`} component={Link} variant='outlined'>
                        Public view
                    </Button>
                    <Button variant='outlined' color='error' onClick={() => removeScript()} startIcon={<DeleteIcon/>}>Delete</Button>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', gap: 2 }}>
            <Card sx={{ maxHeight: '845px', width: '100%'}}>
                <CardContent sx={{ pt: 0, overflow: 'scroll', height: '100%' }}>
                    <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                        <Stack sx={{ overflow: 'scroll' }} direction={'row'} gap={1} >
                            <Box>
                                <Chip sx={{ mt: 1 }} size='small' label="browser"/>
                            </Box>
                            <Box>
                                <Chip sx={{ mt: 1 }} size='small' label="page"/>
                            </Box>
                            <Box>
                                <Chip sx={{ mt: 1 }} size='small' label="baseUrl"/>
                            </Box>
                            <Divider sx={{ height: 'auto' }} orientation='vertical' variant='middle' />
                            {
                                JSON.parse(data.variables).map(variable => {
                                    if (variable.name == '') return
                                    return (
                                        <Box key={variable.name}>
                                            <Chip sx={{ mt: 1 }} size='small' label={variable.name}/>
                                        </Box>
                                    )
                                })
                            }
                        </Stack>
                        {/* <CircularProgress sx={{alignSelf: 'center', mr: 2}} size={24}/> */}
                        <Box ml={1} sx={{ alignSelf: 'end' }}>
                            <Button
                                startIcon={loading? <CircularProgress size={14} sx={{color: 'primary'}} /> : <CheckIcon color='success'/>}
                                variant="outlined"
                                disabled
                                size='small'
                                sx={{py: 0, my: 1}}
                            >
                                {loading ? 'Saving' : 'Saved'}
                            </Button>
                        </Box>
                        
                    </Box>
                    
                    <CodeEditor initialCode={data.code} url={data.baseUrl} codeUpdated={(newCode) => {setCode(newCode)}} />  
                    <Box my={1}>
                        <Button 
                            startIcon={testLoading ? <CircularProgress size={14} sx={{color: 'gray'}} /> : null}
                            onClick={() => handleClick(data.baseUrl, JSON.parse(data.variables))} 
                            disabled={code == null || code.length < 1 ? true : testLoading ? true : false}
                            sx={{ px: 8 }} 
                            variant='contained'>
                                {testLoading ? 'Running' : 'Run script'}
                        </Button>
                    </Box> 
                    <Divider sx={{ mb: 1 }}/>
                    <Typography>Output:</Typography>
                    <Output data={output?.scrapedData ? output.scrapedData : output?.error ? output.error : {}}/>
                </CardContent>
            </Card>
            <Stack minWidth={350} maxWidth={350} gap={2}>
            <Card>
                <CardContent>
                    <Stack pb={2} gap={1}>
                        <Typography mb={0} variant='subtitle2'>Name</Typography>
                        <EditableText fullWidth variant="h6" size={'small'} onChange={value => updateName(value)} value={data.name}/>
                        <Typography mb={0} variant='subtitle2'>Description</Typography>
                        <EditableText fullWidth size={'small'} onChange={value => updateDescription(value)} value={data.description}/>
                    </Stack>
                    <Divider/>
                    <Stack pb={2} gap={1}>
                        <TextField sx={{mt: 2}} value={data.baseUrl} label="URL" onChange={(el) => updateUrl(el.target.value)}/>
                    </Stack>
                    <Divider/>
                    <Stack pt={2} gap={2}>
                        <ListItem secondaryAction={<Switch checked={data.published} onChange={() => updatePublish(!data.published)}/>}>
                            <ListItemIcon>
                                {data.published ? <PublicIcon/> : <PublicOffIcon/>}
                            </ListItemIcon>
                            <ListItemText primary={data.published ? 'Unpublish' : 'Publish'}/>
                        </ListItem>
                    </Stack>
                    
                    {/* <Box sx={{ display: 'flex', justifyContent: 'space-between' }} pt={2}>
                        <FormControlLabel control={<Switch/>} label="Publish"/>
                        <Chip label={data.deployed ? 'Deployed' : 'Not Deployed'}/>
                    </Box> */}
                </CardContent>
            </Card>
            <Card>
                <CardContent>
                    <VariableList variablesChanged={(variables) => updateVariables(variables)} initialData={JSON.parse(data.variables)}/>
                </CardContent>
            </Card>            
            <Scheduler script={data} auth={auth}/>
            </Stack>
            </Box>
            </>
            )};
        }}
    </FirestoreDocument>
  )
}

export default EditScript
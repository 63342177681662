import Firebase from 'firebase/compat/app'
import { FirestoreProvider } from 'react-firestore'
import React from 'react'
// import ReactGA from 'react-ga'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from '@mui/material/styles';

import RequireAuth from './misc/RequireAuth';
import ErrorBoundary from './misc/ErrorBoundary'
import Router from './Router'
import Layout from './layout/Layout'
import '../styles/global'
import '../index.css'
import theme from '../theme/theme.js'



const App = () => (
  <ThemeProvider theme={theme}>
    <FirestoreProvider firebase={Firebase}>
      <BrowserRouter>
        <ErrorBoundary>
          <RequireAuth>
            <Layout>
              <Router/>
            </Layout>
          </RequireAuth>
        </ErrorBoundary>
      </BrowserRouter>
    </FirestoreProvider>
  </ThemeProvider>
)

export default App

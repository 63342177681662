import React from 'react'
import { Chip } from '@mui/material'
import { colors } from '@mui/material'
import { styled } from '@mui/material/styles';

const StyledChip = ({ label, color, icon }) => {
    const StyledChipIcon = styled(Chip)`
        & .MuiChip-icon {
            color: ${colors[color][800]};
        }
    `
    return (
        <StyledChipIcon icon={icon} sx={{ backgroundColor: colors[color][50], color: colors[color][800] }} label={label} />
    )
}

export default StyledChip
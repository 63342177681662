import React, { useState, useEffect } from 'react'
import Editor from '@uiw/react-textarea-code-editor';
import { IconButton, Paper, TextField, Box, Divider, LinearProgress } from '@mui/material';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import CheckIcon from '@mui/icons-material/Check'
import { motion, AnimatePresence } from 'framer-motion';
import { keyframes } from '@emotion/react';
import { app } from '../../firebase'
const functions = app.functions()
// Import firebase functions

const rainbow = keyframes`
  0% {color: rgba(255, 0, 0, 0.4);}
  9% {color: rgba(255, 154, 0, 0.4);}
  18% {color: rgba(208, 222, 33, 0.4);}
  27% {color: rgba(79, 220, 74, 0.4);}
  36% {color: rgba(63, 218, 216, 0.4);}
  45% {color: rgba(47, 201, 226, 0.4);}
  54% {color: rgba(28, 127, 238, 0.4);}
  63% {color: rgba(95, 21, 242, 0.4);}
  72% {color: rgba(186, 12, 248, 0.4);}
  81% {color: rgba(251, 7, 217, 0.4);}
  100% {color: rgba(255, 0, 0, 0.4);}
`;

const CodeEditor = ({initialCode, url, codeUpdated, disabled}) => {

  const [ code, setCode ] = useState(initialCode)
  const [ oldCode, setOldCode ] = useState(null) // Temporary state to hold the updated code
  const [ showPaper, setShowPaper ] = useState(false)
  const [ shimmer, setShimmer ] = useState(false)
  const [ aiPrompt, setAiPrompt ] = useState('') // State to hold the AI prompt
  const [ loading, setLoading ] = useState(false) // State to handle loading state

  const updateCode = (newCode) => {
    setCode(newCode)
    codeUpdated(newCode)
  }

  const handleAiPrompt = async () => {
    setLoading(true);
    setOldCode(code ? code : '')
    const codePromptAi = functions.httpsCallable('codePromptAi');
    const result = await codePromptAi({ url: url, message: aiPrompt, currentCode: code });
    let updatedCode = result.data.choices[0].message.content
    updatedCode = updatedCode.replace(/^```javascript\n|```$/g, ''); // Remove ```javascript and ``` from the start and end of the string, including the newline after ```javascript
    updateCode(updatedCode); // Set the updated code to tempCode
    setLoading(false);
  }

  const handleCheck = () => {
    setOldCode(null); // Keep the updated code
    setShowPaper(false); // Close the paper
  }

  const handleClose = () => {
    updateCode(oldCode); // Revert back to the initial code
    setOldCode(null)
    setShowPaper(false); // Close the paper
  }

  useEffect(() => {
    codeUpdated(initialCode)
    setTimeout(() => {
      setShimmer(true)
      setTimeout(() => {
        setShimmer(false)
      }, 2000)
    }, 3000)

    const handleEsc = (event) => {
      if(event.key === 'Escape' && showPaper){
        if(oldCode !== null) {
          updateCode(oldCode);
        }
        setShowPaper(false);
      }
    }

    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    }
  }, [initialCode, showPaper, oldCode])

  const handleKeyPress = (event) => {
    if(event.key === 'Enter'){
      event.preventDefault(); // Prevent new line
      handleAiPrompt();
    }
  }

  return ( 
    <Box sx={{position: 'relative', display: 'flex', flexDirection: 'column'}}>
      <Editor
        disabled={disabled || loading} // Disable the editor when loading
        value={code}
        language="js"
        onChange={(evn) => updateCode(evn.target.value)}
        padding={15}
        style={{
          minHeight: '450px',
          fontSize: '16px',
          border: '1px solid lightgray',
          borderRadius: '10px',
          backgroundColor: "#fafafa",
          overflow: 'scroll',
          fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace' 
        }}
      />
      {!disabled && <Box sx={{position: 'relative'}}>
        <IconButton 
          sx={{ 
            position: 'absolute', 
            bottom: '10px', 
            right: '10px' ,
            animation: shimmer ? `${rainbow} 2s linear infinite` : 'none',
            '&:hover': {
              animation: `${rainbow} 2s linear`
            }
          }}
          onClick={() => setShowPaper(!showPaper)}
        >
          <AutoAwesomeIcon />
        </IconButton>
        <AnimatePresence>
          {showPaper && (
            <motion.div
              initial={{ opacity: 0, x: 5, y: 5 }}
              animate={{ opacity: 1, x: 0, y: 0 }}
              exit={{ opacity: 0, x: 5, y: 5 }}
              transition={{ duration: 0.2 }}
            >
              <Box sx={{position: 'absolute', bottom: 10, left: 10, right: 10}}>
                <Paper variant='outlined' sx={{ width: '100%', paddingLeft: 2, paddingRight: 2, paddingTop: 2 }}>
                  <TextField
                    maxRows={3}
                    fullWidth
                    multiline
                    label='AI Prompt'
                    onChange={(evn) => setAiPrompt(evn.target.value)} // Update the AI prompt state
                    onKeyPress={handleKeyPress} // Add key press event
                  />
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                    {loading && <LinearProgress sx={{ width: '100%', marginRight: '10px' }}/>}
                    <IconButton size='small' color="success" onClick={handleCheck} disabled={loading || !oldCode}>
                      <CheckIcon />
                    </IconButton>
                    <IconButton size='small' color="error" onClick={handleClose} disabled={loading || !oldCode}>
                      <CloseIcon />
                    </IconButton>
                    <Divider orientation="vertical" variant="middle" flexItem />
                    <IconButton size='small' color="primary" onClick={handleAiPrompt} disabled={loading}>
                      <KeyboardReturnIcon />
                    </IconButton>
                    <IconButton size='small' onClick={() => setShowPaper(!showPaper)} disabled={loading}>
                      <CloseIcon/>
                    </IconButton>
                  </Box>
                </Paper>
              </Box>
            </motion.div>
          )}
        </AnimatePresence>
      </Box>}
    </Box>
  )
}

export default CodeEditor


async function executeTestScript(baseUrl, code, args) {
    try {
        // Switch out to store URL in env
        const req = await fetch(process.env.REACT_APP_SCRAPING_SERVICE_URL, {
            headers: {
            //   'Accept': 'application/json',
              "Content-Type": "application/json"
            },
            method: "POST",
            body: JSON.stringify({
                baseUrl: baseUrl, 
                code: code, 
                args: args
            })
        })
        const jsonData = await req.json()
        console.log(jsonData)
        return jsonData
    } catch(err) {
        console.log(err)
        return err
    }
}
export default executeTestScript
import React from 'react'
import { Typography } from '@mui/material'
import { FirestoreCollection } from 'react-firestore'
import ScriptGrid from '../../components/ScriptGrid'

import Loading from '../../components/Loading'

const Community = () => {
  return (
    <FirestoreCollection
      path={'scripts'}
      // sort="createdOn:desc"
      filter={['published', '==', true]}
    >
      { ({error, isLoading, data}) => {

        if (error) {
          return <Error error={error} />
        }

        if (isLoading) {
          return <Loading/>
        }

        if (data.length === 0) {
          return <Typography variant='h4'>No posts yet!</Typography>
        }

        return <ScriptGrid  title="Community scripts" scripts={data} />
      }}
    </FirestoreCollection>
  )
}

export default Community
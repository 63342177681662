import React from 'react'
import { Typography, Box, Grid } from '@mui/material'
import ScriptGrid from '../../components/ScriptGrid'
import { FirestoreCollection } from 'react-firestore'
import FirebaseAuth from '../misc/FirebaseAuth'
import Loading from '../../components/Loading'
import Error from '../misc/Error'

const Scripts = () => {
  return (<FirebaseAuth>
  { ({isLoading, error, auth}) => {

    if (!auth || isLoading || error) {
      return <Error/>
    }

    return (
      <>
        <FirestoreCollection
          path={'scripts'}
          filter={[['createdBy', '==', auth.uid], ['published', '==', false]]}
        >
          { ({error, isLoading, data}) => {

            if (error) {
              console.log(auth.uid)
              console.log(error)
              return <Error error={error} />
            }

            if (isLoading) {
              return <Loading/>
            }

            if (data.length === 0) {
              return <Typography variant='h4'>No private scripts yet!</Typography>
            }

            return <ScriptGrid toEdit title="Your private scripts" scripts={data} />
          }}
        </FirestoreCollection>
        <div className='h-6'></div>
        <FirestoreCollection
          path={'scripts'}
          filter={[['createdBy', '==', auth.uid], ['published', '==', true]]}
        >
          { ({error, isLoading, data}) => {

            if (error) {
              console.log(auth.uid)
              console.log(error)
              return <Error error={error} />
            }

            if (isLoading) {
              return <Loading/>
            }

            if (data.length === 0) {
              return <Typography variant='h4'>No public scripts yet!</Typography>
            }

            return <ScriptGrid toEdit title="Your public scripts" scripts={data} />
          }}
        </FirestoreCollection>
      </>
    )
  }}</FirebaseAuth>
  )
}

export default Scripts

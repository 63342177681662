import { getAuth } from "firebase/auth";
import { useState, useEffect } from "react"
import { getFirestore, query, collectionGroup, where, getDocs, orderBy, limit, startAfter, endBefore, limitToLast, collection, doc} from "firebase/firestore";
import { app } from "../firebase";

function useResults(pageLimit, deploymentId) {
    const [error, setError] = useState(false)
    const [results, setResults] = useState(null)
    const [loading, setLoading] = useState(true)

    const previousPage = async () => {
        try {
            setLoading(true)
            const db = getFirestore(app)
            const auth = getAuth(app)
            let resultsQuery, countQuery
            if (deploymentId) {
                resultsQuery = query(collection(db, 'deployed', deploymentId, 'results'), orderBy('createdOn', 'desc'), endBefore(results.data[0]), limitToLast(pageLimit))
                countQuery = query(collection(db, 'deployed', deploymentId, 'results'), orderBy('createdOn', 'desc'))
            } else {
                resultsQuery = query(collectionGroup(db, 'results'), where('createdBy', '==', auth.currentUser.uid), orderBy('createdOn', 'desc'), endBefore(results.data[0]), limitToLast(pageLimit));
                countQuery = query(collectionGroup(db, 'results'), where('createdBy', '==', auth.currentUser.uid), orderBy('createdOn', 'desc'));
            }
            const querySnapshot = await getDocs(resultsQuery);
            const countSnapshot = await getDocs(countQuery)
            let resultData = {data: querySnapshot.docs, count: countSnapshot.size}
            setLoading(false)
            setResults(resultData)
        } catch(e) {
            setError(e)
        }
    }


    const nextPage = async () => {
        try {
            setLoading(true)
            const db = getFirestore(app)
            const auth = getAuth(app)
            let resultsQuery, countQuery
            if (deploymentId) {
                resultsQuery = query(collection(db, 'deployed', deploymentId, 'results'), orderBy('createdOn', 'desc'), startAfter(results.data[results.data.length - 1]), limit(pageLimit))
                countQuery = query(collection(db, 'deployed', deploymentId, 'results'), orderBy('createdOn', 'desc'))
            } else {
                resultsQuery = query(collectionGroup(db, 'results'), where('createdBy', '==', auth.currentUser.uid), orderBy('createdOn', 'desc'), startAfter(results.data[results.data.length - 1]), limit(pageLimit));
                countQuery = query(collectionGroup(db, 'results'), where('createdBy', '==', auth.currentUser.uid), orderBy('createdOn', 'desc'));
            }
            const querySnapshot = await getDocs(resultsQuery);
            const countSnapshot = await getDocs(countQuery)
            let resultData = {data: querySnapshot.docs, count: countSnapshot.size}
            setLoading(false)
            setResults(resultData)
        } catch(e) {
            setError(e)
        }
    }

    useEffect( () => {
        
        const getResults = async () => {
            try {
                const db = getFirestore(app)
                const auth = getAuth(app)
                let resultsQuery, countQuery
                if (deploymentId) {
                    resultsQuery = query(collection(db, 'deployed', deploymentId, 'results'), orderBy('createdOn', 'desc'), limit(pageLimit))
                    countQuery = query(collection(db, 'deployed', deploymentId, 'results'), orderBy('createdOn', 'desc'))
                } else {
                    resultsQuery = query(collectionGroup(db, 'results'), where('createdBy', '==', auth.currentUser.uid), orderBy('createdOn', 'desc'), limit(pageLimit));
                    countQuery = query(collectionGroup(db, 'results'), where('createdBy', '==', auth.currentUser.uid), orderBy('createdOn', 'desc'));
                }                
                const querySnapshot = await getDocs(resultsQuery);
                const countSnapshot = await getDocs(countQuery)
                let resultData = {data: querySnapshot.docs, count: countSnapshot.size}
                setLoading(false)
                setResults(resultData)
            } catch(e) {
                console.log(e)
                setError(e)
            }
        }
        getResults()

    }, [pageLimit])
    return [ error, loading, results, previousPage, nextPage ]
}
export default useResults
import React from 'react'
import { useParams, useOutletContext, Link, useNavigate } from 'react-router-dom'
import { Box, CardContent, Card, Typography, Button, Stack, TextField } from '@mui/material'
import { FirestoreDocument } from 'react-firestore'

import Error from '../misc/Error'
import duplicateScript from '../../actions/duplicateScript'
import LikeScriptButton from '../../components/LikeScriptButton'
import Loading from '../../components/Loading'
import CodeEditor from '../../components/CodeEditor'
import VariableList from '../../components/VariableList'

const ScriptHeader = ({ data, auth }) => {
    const navigate = useNavigate()
    const duplicate = async () => {
        let duplicatedScript = await duplicateScript(data)
        navigate(`../${duplicatedScript.id}/edit`)
    }
    return (
        <Box display="flex" justifyContent={'space-between'}>
            <Box mb={1}>
                <Typography variant="h4">{ data.name }</Typography>
                {/* <Typography variant='subtitle1'>{ data.description }</Typography> */}
            </Box>
            <Box >
                { data.createdBy == auth.currentUser.uid ? <Button variant='outlined' color='secondary' sx={{mr: 2}} component={Link} to={'./edit'}>
                    Edit
                </Button> : null}
                <Button variant='outlined' onClick={() => duplicate()} sx={{mr: 2}}>
                    Duplicate
                </Button>
                <LikeScriptButton variant="outlined"  script={data} />
            </Box>
        </Box>
    )
}

const ViewScript = () => {
  const { auth } = useOutletContext()
  let { scriptId } = useParams()
  return (
    
    // <FirebaseAuth>
        <FirestoreDocument
            path={`scripts/${scriptId}`}
        >
            { ({error, isLoading, data}) => {

            if (isLoading) {
                return <Loading/>
            }
            if (error) return <Error/>
            if (!data.published && data.createdBy != auth.currentUser.uid) {
                return <Error/>
            }

            // const userLike = data[0]

            return (
                <Box>
                    <ScriptHeader data={data} auth={auth}/>
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        <Card sx={{ width: '100%' }}>
                            <CardContent>
                                <CodeEditor initialCode={data.code} disabled codeUpdated={() => null}/>
                            </CardContent>
                        </Card>
                        <Stack minWidth={350} maxWidth={350} gap={2}>
                            <Card>
                                <CardContent>
                                    <Typography variant='subtitle2'>Description</Typography>
                                    <Typography>{data.description}</Typography>
                                    <TextField fullWidth disabled sx={{mt: 2}} value={data.baseUrl} label="URL" />
                                </CardContent>
                            </Card>
                            <Card>
                                <CardContent>
                                    <VariableList initialData={JSON.parse(data.variables)} disabled variablesChanged={() => null}/>
                                </CardContent>
                            </Card>
                        </Stack>
                    </Box>
                </Box>)
            }}
        </FirestoreDocument>
    // </FirebaseAuth>
  )
}

export default ViewScript
import React from 'react'

import logIn from '../../actions/logIn'
import FirebaseAuth from '../misc/FirebaseAuth'
import Error from '../misc/Error'
import Profile from './Profile'
import Subscription from './Subscription'
import { Box, Typography } from '@mui/material'
import Stripe from '../../components/Stripe'
// import {
//   Page,
// } from '../../styles/layout'

const Account = () => (
    <FirebaseAuth>
      { ({isLoading, error, auth}) => {

        if (isLoading) {
          return <p>loading...</p>
        }

        if (error) {
          return <Error error={error} />
        }

        if (!auth) {
          return <div>
            <p>Log in to see your account</p>
            <button onClick={logIn}>Log in</button>
          </div>
        }

        return <Box>
          <Box>
            <Profile auth={auth} />
            <br/>
            {/* <Subscription auth={auth} /> */}
            <Stripe auth={auth}/>
          </Box>
        </Box>
      }}
    </FirebaseAuth>
)

export default Account

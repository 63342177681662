import React from 'react'
import { Box } from '@mui/material'
import { prettyPrintJson } from 'pretty-print-json';

const Output = ({ data }) => {
  return (
    <Box sx={{
        display: 'grid',
        minHeight: '250px',
        fontSize: '16px',
        border: '1px solid lightgray',
        borderRadius: '10px',
        backgroundColor: "#FAFAFA",
        overflow: 'scroll',
        p: 2
      }}>
        <code>{JSON.stringify(data, null, 2)}</code>
    </Box>
  )
}

export default Output
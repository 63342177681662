import React from 'react'
import { Outlet } from 'react-router-dom'
import FirebaseAuth from '../misc/FirebaseAuth'
import Loading from '../../components/Loading'

const Deployment = () => {
  return (
    <FirebaseAuth>
      { ({isLoading, error, auth}) => {

        if (isLoading) {
          return <Loading/>
        }

        if (error) {
          return <Error error={error} />
        }

        if (!auth) {
          return <div>
            <p>Log in to see your account</p>
            <button onClick={logIn}>Log in</button>
          </div>
        }

        return (<>
        
        {/* <Typography variant='h4' sx={{mb: 2}}>My script</Typography> */}
        <Outlet context={auth}/>
        </>)
      }}
    </FirebaseAuth>
  )
}

export default Deployment
import React from 'react'
import { Box, Typography, Grid } from '@mui/material'
import { Link } from 'react-router-dom'

import DeploymentItem from '../../components/DeploymentItem'


const DeployedList = ({ deployed, title }) => {
  return (
    <Box>
        <Typography variant='h4'>{title}</Typography>
        <Grid mt={0} container spacing={3}>
            {deployed.map(deployment => (
            <Grid item xs={4}>
                <DeploymentItem 
                    deploymentId={deployment.id}
                    link={`/deployment/${deployment.id}`} 
                    key={deployment.id} 
                    name={deployment.name} 
                    description={deployment.description} 
                    likes={deployment._likeCount}
                    deployed={deployment.deployed}
                    schedule={deployment.schedule}
                    nextRun={deployment.nextRun.toDate()}
                    published={deployment.published}/>
            </Grid>
            ))}
        </Grid>
    </Box>
  )
}

export default DeployedList
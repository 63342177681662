import React, { useEffect, useState } from 'react'
import { FirestoreCollection } from 'react-firestore'

import { Box, Typography } from '@mui/material'
import FirebaseAuth from '../misc/FirebaseAuth'
import Loading from '../../components/Loading'
import Error from '../misc/Error'
import ResultsTable from '../../components/ResultsTable'

const Results = () => {
    // const db = getFirestore(app)

    // const getResults = async (auth) => {
    //     const resultsQuery = query(collectionGroup(db, 'results'), where('createdBy', '==', auth.uid));
    //     const querySnapshot = await getDocs(resultsQuery);
    //     return querySnapshot.map((doc) => {
    //         return doc.data()
    //     });
    // }

    return (<FirebaseAuth>
        { ({isLoading, error, auth}) => {
      
          if (!auth || isLoading) {
            return <Loading/>
          }
          if (error) {
            return <Error/>
          }
          return (<Box>
              <Typography mb={3} variant='h4'>Result logs</Typography>
              <ResultsTable title="Result Logs"/>
            </Box>)
        }}</FirebaseAuth>
    )
}

export default Results
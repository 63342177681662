import React, { useState, useEffect } from 'react'
import { Typography } from '@mui/material'
import ScriptGrid from '../../components/ScriptGrid'
import { getFirestore, query, collection, where, getDocs, orderBy } from "firebase/firestore";
import Loading from '../../components/Loading'
import Error from '../misc/Error'

const FeaturedSection = ({ title }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const db = getFirestore();
        const q = query(collection(db, 'scripts'), where('published', '==', true), where('_likeCount', '>', 0), orderBy('_likeCount', 'desc'));
        const querySnapshot = await getDocs(q);
        setData(querySnapshot.docs.map(doc => ({...doc.data(), id: doc.id})));
      } catch (e) {
        setError(e);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  if (error) {
    return <Error error={error} />
  }

  if (isLoading) {
    return <Loading/>
  }

  if (data.length === 0) {
    return <Typography variant='h4'>No posts yet!</Typography>
  }

  return <ScriptGrid title={title} scripts={data} />
}

const Featured = () => {
  return (
    <>
      <FeaturedSection title="Most liked" />
    </>
  )
}

export default Featured

import Firebase from 'firebase/compat/app'
import ReactGA from 'react-ga'
import slugify from 'slugify'

import { prepareDocForCreate } from './helpers/firestoreHelpers'

const createScript = values => {

  ReactGA.event({
    category: 'Script',
    action: 'Create script',
  })

  values.baseUrl = ''
  values.slug = slugify(values.name, {lower: true})
  values.published = false
  values.deployed = false
  values.variables = '[]'
  values.code = `// Some examples to get you started:
//
// Navigate to the baseUrl page
// await page.goto(baseUrl)
//
// Grab text from an element using a variable as selector
// const myText = await page.evaluate(mySelector => document.querySelector(mySelector).innerText, mySelector)`
  values._likeCount = 0

  return Firebase.firestore()
    .collection('scripts')
    .add(prepareDocForCreate(values))
    // .then( () => values)
    // .catch( error => {
    //   alert(`Whoops, couldn't create the post: ${error.message}`)
    // })
}

export default createScript

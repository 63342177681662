import React from 'react'
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';

import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer';

import logIn from '../../actions/logIn'
import FirebaseAuth from '../misc/FirebaseAuth'

import {
  // HeaderFooterWrapper,
  // Header,
  // Footer,
} from '../../styles/layout'
import {
  HeaderLink,
} from '../../styles/links'
import { Box } from '@mui/system';
import SideDrawer from '../../components/SideDrawer';

const LayoutContainer = ({children}) => {
  return (
    <Box component="main" sx={{ width: '100%', p: 4}}><Toolbar/>{children}</Box>
  )
}

const Layout = ({children}) => {
  return (
  <Box sx={{ display: 'flex' }}>
    <Navbar/>
    <CssBaseline/>
    <SideDrawer/>
    <LayoutContainer>
      {children}
    </LayoutContainer>
  </Box>
)
}

// const Layout = ({children}) => (
//   <HeaderFooterWrapper>
//     <Header>
//       <HeaderLink to="/">Firefly</HeaderLink>
//       <link
//         rel="stylesheet"
//         href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
//       />
//       <link
//         rel="stylesheet"
//         href="https://fonts.googleapis.com/icon?family=Material+Icons"
//       />
//       <div style={{float: 'right'}}>
//         <HeaderLink to="/search">
//           <span role="img" aria-label="search">🔎</span>
//         </HeaderLink>
//         {' '}
//         <FirebaseAuth>
//           { ({isLoading, error, auth}) => {
//             if (isLoading) {
//               return '...'
//             }
//             if (error) {
//               return '⚠️ login error'
//             }
//             if (auth) {
//               return <HeaderLink to={`/account`}>
//                 <span role="img" aria-label="account">👤</span>
//               </HeaderLink>
//             } else {
//               return <button onClick={logIn}>log in</button>
//             }
//           }}
//         </FirebaseAuth>
//       </div>
//     </Header>

//     {children}

//     <Footer>
//       © {(new Date()).getFullYear()}
//     </Footer>

//   </HeaderFooterWrapper>
// )

export default Layout

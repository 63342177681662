import Firebase from 'firebase/compat/app'
import ReactGA from 'react-ga'
import slugify from 'slugify'

import { prepareDocForCreate } from './helpers/firestoreHelpers'

const createScript = values => {

  ReactGA.event({
    category: 'Script',
    action: 'Duplicate script',
  })

  delete values.id
  delete values.createdOn
  values.slug = slugify(values.name, {lower: true})
  values.published = false
  values.deployed = false
  values.name = `${values.name} (Duplicate)`
//   values.variables = '[]'
//   values.code = '// Dont fortget to wash your hands!'
  values._likeCount = 0

  return Firebase.firestore()
    .collection('scripts')
    .add(prepareDocForCreate(values))
    // .then( () => values)
    // .catch( error => {
    //   alert(`Whoops, couldn't create the post: ${error.message}`)
    // })
}

export default createScript
import React from 'react'
import { Link } from 'react-router-dom'
import { Card, CardActionArea, CardContent, Typography, Box, Stack, Divider } from '@mui/material'
import PublicOffIcon from '@mui/icons-material/PublicOff'
import PublicIcon from '@mui/icons-material/Public'
import FavoriteIcon from '@mui/icons-material/Favorite'

const ScriptListItem = ({ link, key, name, description, published, likes, code }) => {
  const trimmedDescription = description.length > 230 ? `${description.slice(0,230).trim()}...` : description;
  const trimmedCode = code.length > 100 ? `${code.slice(0,100).trim()}...` : code;

  return (
    <CardActionArea sx={{borderRadius: 2, flex: 1}} to={link} component={Link}>
      <Card key={key}>
        <CardContent sx={{height: '300px', overflow: 'hidden', textOverflow: 'ellipsis', minWidth: '300px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
            <Box>
              <Typography variant='subtitle1'>{name}</Typography>
              <Typography variant='p' color={'gray'}>{trimmedDescription}</Typography>
            </Box>
            <Box sx={{ 
                height: '75px', 
                overflow: 'hidden', 
                position: 'relative',
                mt: 2
            }}>
                <Typography variant='subtitle2'>Code</Typography>
                <Typography variant='body2' color={'gray'}>{trimmedCode}</Typography>
                <Box sx={{ 
                    position: 'absolute', 
                    bottom: 0, 
                    width: '100%', 
                    height: '50%', 
                    backgroundImage: 'linear-gradient(to top, white, transparent)' 
                }}/>
            </Box>
        </CardContent>
        <Divider/>
        <CardContent sx={{"&:last-child": {pb: 2}}}>
          <Stack direction={'row'} justifyContent="space-between">
            <div>
            { likes > 0 ? <Box display={'flex'} gap={1}><FavoriteIcon sx={{color: '#AD1457'}}/><Typography variant='subtitle1'>{likes}</Typography></Box> : <Box display={'flex'} gap={1}><FavoriteIcon sx={{color: 'rgba(0, 0, 0, 0.38)'}} /><Typography sx={{color: 'rgba(0, 0, 0, 0.38)'}}>-</Typography></Box> }
            </div>
            <div>
            {published ? <PublicIcon color='primary'/> : <PublicOffIcon color='primary'/>}
            </div>
          </Stack>
        </CardContent>
      </Card>
    </CardActionArea>
  )
}

export default ScriptListItem
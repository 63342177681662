import React from 'react'
import { Button } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { Link } from 'react-router-dom'

const NewScriptButton = () => {
  return (
    <Button component={Link} to={'/script/new'} sx={{ borderRadius: 4, mr: 2 }} variant='contained' color='secondary' startIcon={<AddIcon/>} >New Script</Button>
  )
}

export default NewScriptButton
import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import { Link } from 'react-router-dom';

import FirebaseAuth from '../../views/misc/FirebaseAuth'
import NewScriptButton from './NewScriptButton';

import logo from '../../assets/images/logo.png'

const pages = ['Scripts', 'Runs'];


const Navbar = () => {
  return (
    // <AppBar sx={{background: 'linear-gradient(80deg, #E6FAE7, #FFE0E3)'}}>
    <AppBar sx={{background: '#1D2671', zIndex: (theme) => theme.zIndex.drawer + 1}}>
      <Toolbar>
        <img width={35} height={35} src={logo} />
        <Typography pl={1} variant="h6" component="div" sx={{ flexGrow: 1 }}>ScraperScript</Typography>
        <FirebaseAuth>
          { ({isLoading, error, auth}) => {
            if (isLoading) {
              return '...'
            }
            if (error) {
              return '⚠️ login error'
            }
            if (auth) {
              return (<>
                <NewScriptButton/>
                <IconButton component={Link} to="/account" sx={{ p: 0 }}>
                 <Avatar alt="Profile picture" src={auth.photoURL} />
                </IconButton></>)
            } else {
              return <Button onClick={logIn}>Log in</Button>
            }
          }}
        </FirebaseAuth>
      </Toolbar>
    </AppBar>

  )
}

export default Navbar;
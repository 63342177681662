import React, { useState } from 'react'
import { Card, Box, CardContent, TextField, Stack, Typography, Button, Divider, CircularProgress} from '@mui/material'
import { useForm } from 'react-hook-form'
import { getDoc } from 'firebase/firestore'
import { useNavigate } from 'react-router-dom'

import createScript from '../../actions/createScript'

const NewScript = () => {
  const { register, formState: { errors }, handleSubmit } = useForm();
  const [ loading, setLoading ] = useState(false)
  const navigate = useNavigate()
  const onSubmit = async data => {
    setLoading(true)
    try {
        const newScriptRef = await createScript(data)
        const newScriptDoc = await getDoc(newScriptRef)
        console.log(newScriptDoc.id)
        navigate(`../${newScriptDoc.id}/edit`, {replace: true})
        setLoading(false)
        return
    } catch(e) {
        setLoading(false)
        console.log(e)
    }
    
    // Create new record in Firestore
  };
  return (
    <Box>
        <Card sx={{ maxWidth: '450px', mx: 'auto' }}>
            <CardContent >
                <Typography variant='h4'>Start new script</Typography>
                <Typography mb={3} variant='subtitle2' color={'gray'}>Let's start a new script by adding a name and description.</Typography>
                <Divider/>
                <Stack spacing={2} mt={3}>
                    <TextField {...register("name", { required: true })} fullWidth variant='outlined' label="Name" error={errors.name?.type === 'required'} helperText={errors.name?.type === 'required' && "Script name is required"}/>
                    <TextField {...register("description", { required: true })} fullWidth variant='outlined' label="Description" error={errors.description?.type === 'required'} helperText={errors.description?.type === 'required' && "Script description is required"}/>
                    <Button startIcon={loading ? <CircularProgress size={14} sx={{color: 'gray'}} /> : null} onClick={handleSubmit(onSubmit)} disabled={loading} variant='contained' size='large'>Start coding!</Button>
                </Stack>
            </CardContent>
        </Card>
    </Box>
  )
}

export default NewScript
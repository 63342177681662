import React from 'react'
import { Drawer, Toolbar, Box, List, ListItem, ListItemIcon, ListItemButton, ListItemText, Divider } from '@mui/material'
import ArticleIcon from '@mui/icons-material/Article'
import PeopleIcon from '@mui/icons-material/People'
import WhatshotIcon from '@mui/icons-material/Whatshot'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import StorageIcon from '@mui/icons-material/Storage'
import { useLocation, useNavigate } from 'react-router-dom'
import _ from 'lodash'

const drawerWidth = 220
const pages = [
    {text: 'featured', icon: <WhatshotIcon/>},
    {text: 'community', icon: <PeopleIcon/>},
]
const privatePages = [
    {text: 'scripts', icon: <ArticleIcon/>},
    {text: 'deployed', icon: <CloudUploadIcon/>},
    {text: 'logs', icon: <StorageIcon/>}
]

const SideDrawer = () => {
  const navigate = useNavigate()
  const location = useLocation()
  return (
    <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: 'auto' }}>
        <List>
            {privatePages.map((item, index) => (
              <ListItem sx={{ background: (theme) => location.pathname == `/${item.text}` ? theme.palette.grey[100] : 'inherit' }} key={item.text} disablePadding>
                <ListItemButton onClick={() => navigate(item.text)}>
                  <ListItemIcon>
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText primary={_.startCase(item.text)} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          <Divider/>
          <List>
            {pages.map((item, index) => (
              <ListItem sx={{ background: (theme) => location.pathname == `/${item.text}` ? theme.palette.grey[100] : 'inherit' }} key={item.text} disablePadding>
                <ListItemButton onClick={() => navigate(item.text)}>
                  <ListItemIcon>
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText primary={_.startCase(item.text)} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>          
        </Box>
      </Drawer>
  )
}

export default SideDrawer
import { Card, CardContent, CardHeader, Divider, Button, CircularProgress, Typography, Grid, Box, Accordion, AccordionSummary, AccordionDetails, List, ListItem, ListItemIcon } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import React, { useEffect, useState } from 'react'
import { app } from '../../firebase'

const productDescriptions = [
    {
        product: 'Base',
        includedItems: [
            {feature: '10,000 scraping hours per month', icon: <HourglassEmptyIcon />},
            {feature: 'AI code helper', icon: <AutoAwesomeIcon />}
        ]
    },
    {
        product: 'Scale',
        includedItems: [
            {feature: '50,000 scraping hours per month', icon: <HourglassEmptyIcon />},
            {feature: 'AI code helper', icon: <AutoAwesomeIcon />}
        ]
    }
]

const Stripe = ({auth}) => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [userSubscription, setUserSubscription] = useState(null);

  useEffect(() => {
    const fetchProducts = async () => {
      const db = app.firestore();
      const activeProducts = await db.collection('products').where('active', '==', true).get();
      const productsData = [];
      for (let doc of activeProducts.docs) {
        const product = doc.data();
        const priceSnap = await doc.ref.collection('prices').get();
        product.prices = priceSnap.docs.map(doc => ({id: doc.id, ...doc.data()}));
        productsData.push(product);
      }
      setProducts(productsData);
    };
    fetchProducts();

    const fetchUserSubscription = async () => {
      const db = app.firestore();
      const snapshot = await db.collection('customers')
        .doc(auth.uid)
        .collection('subscriptions')
        .where('status', 'in', ['trialing', 'active'])
        .get();
      const doc = snapshot.docs[0];
      if (doc) {
        console.log(doc.data())
        setUserSubscription(doc.data());
      } else {
        setUserSubscription(null);
      }
    };
    fetchUserSubscription();
  }, []);

  const createCheckoutSession = async (priceId) => {
    setLoading(true);
    const db = app.firestore();
    const docRef = await db
      .collection('customers')
      .doc(auth.uid)
      .collection('checkout_sessions')
      .add({
        price: priceId,
        success_url: `${window.location.origin}/account`,
        cancel_url: `${window.location.origin}/account`,
      });

    docRef.onSnapshot((snap) => {
      const { error, url } = snap.data();
      if (error) {
        alert(`An error occured: ${error.message}`);
        setLoading(false);
      }
      if (url) {
        window.location.assign(url);
        setLoading(false);
      }
    });
  }
  return (
    <Card sx={{maxWidth: '450px', mx: 'auto'}}>
        <CardHeader title="Subscription"/>
        <Divider/>
        <CardContent>
            <Grid container spacing={2}>
              {products.map((product, index) => (
                <Grid item xs={12} key={index}>
                  <Accordion sx={{border: 'none', boxShadow: 'none'}}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                    >
                      <Typography variant="h6">{product.name}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <List>
                        {productDescriptions.find(item => item.product == product.name).includedItems.map((item, index) => (
                          <ListItem key={index}>
                            <ListItemIcon>
                              {item.icon}
                            </ListItemIcon>
                            {item.feature}
                          </ListItem>
                        ))}
                      </List>
                    </AccordionDetails>
                  </Accordion>
                  {product.prices && product.prices.map((price, index) => (
                    <Box key={index} display="flex" justifyContent="space-between" alignItems="center" mt={2}>
                      <Typography variant="body1">Price: ${(price.unit_amount / 100).toFixed(2)}</Typography>
                      {userSubscription && userSubscription.items[0].plan.id === price.id ? (
                        <Button variant="contained" color="primary" onClick={() => window.location.assign(userSubscription.stripeLink)}>
                          Manage Subscription
                        </Button>
                      ) : (
                        <Button variant="contained" color="primary" onClick={() => createCheckoutSession(price.id)} disabled={loading}>
                          {loading ? <CircularProgress size={24} /> : 'Buy Now'}
                        </Button>
                      )}
                    </Box>
                  ))}
                </Grid>
              ))}
            </Grid>
        </CardContent>
    </Card>
  )
}

export default Stripe
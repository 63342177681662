import React, { useState } from 'react'
import { Button, Card, CardContent, Typography, FormControl, FormHelperText, InputLabel, Select, MenuItem, Box, Chip, Stack } from '@mui/material'
import { FirestoreCollection } from 'react-firestore'
import { useForm } from 'react-hook-form'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'

import deployScript from '../../actions/deployScript'
import { Link, useNavigate } from 'react-router-dom'

import Loading from '../Loading'
import updateDeployed from '../../actions/updateDeployed'

const SchedulerComponent = ({script, data}) => {
    const [ amount, setAmount ] = useState(null)
    const [ unit, setUnit ] = useState(null)
    const { register, formState: { errors }, handleSubmit } = useForm();
    const navigate = useNavigate()

    const scheduleScript = async (schedule) => {
        const { amount, unit } = schedule
        if (data.length > 0) {
            await updateDeployed(data[0].id, {deployed: true, schedule: JSON.stringify({amount, unit}, script)})
        } else {
            let newDeployment = await deployScript(script, {amount, unit})
            navigate(`../../deployment/${newDeployment.id}`)
        }
    }
    const pauseDeployment = async (deployment) => {
        await updateDeployed(deployment.id, {deployed: false})
    }
    const updateAmount = (event) => {
        if (unit == 'minutes' && event.target.value < 5) return 
        setAmount(event.target.value);
      };
    const updateUnit = (event) => {
        if (amount < 5) setAmount(null)
        setUnit(event.target.value)
    }

    const renderAmounts = () => {
        let range = [1,2,3,4,5,6,7,8,9,10,15,20,25,30]
        // return  _.range(1, 10, 4).map(x => <MenuItem value={x}>{x}</MenuItem>)
        return range.map(x => <MenuItem disabled={unit == 'minutes' && x < 5 ? true : false} value={x}>{x}</MenuItem>)
    }
    let isDeployed = data.length > 0 ? data[0].deployed == true ? true : false : false
    return (
        <Card>
            <CardContent>
                <Box display={'flex'} justifyContent={'space-between'}>
                    <Typography variant='h6'>Schedule script</Typography>
                    <Chip sx={{ backgroundColor: isDeployed ? 'success.light' : 'error.light', color: 'white' }} size='small' label={ isDeployed ? 'Deployed' : 'Not Deployed' }/>
                </Box>
                
                <Stack mt={3} gap={2}>
                    <FormControl error={errors.unit?.type === 'required'} fullWidth>
                        <InputLabel id="unit-select-label">Unit</InputLabel>
                        <Select
                        {...register("unit", { required: true })}
                        disabled={isDeployed}
                        labelId="unit-select-label"
                        id="unit-select"
                        value={isDeployed ? JSON.parse(data[0].schedule).unit : unit}
                        label="Unit"
                        onChange={updateUnit}
                        >
                        <MenuItem value={'minutes'}>Minutes</MenuItem>
                        <MenuItem value={'hours'}>Hours</MenuItem>
                        <MenuItem value={'days'}>Days</MenuItem>
                        </Select>
                        {errors.unit?.type === 'required' && <FormHelperText>Unit is required</FormHelperText>}
                    </FormControl>
                    <FormControl error={errors.amount?.type === 'required'} fullWidth>
                        <InputLabel id="amount-select-label">Amount</InputLabel>
                        <Select
                        {...register("amount", { required: true })}
                        disabled={isDeployed}
                        labelId="amount-select-label"
                        id="amount-select"
                        value={isDeployed ? JSON.parse(data[0].schedule).amount : amount}
                        label="Amount"
                        onChange={updateAmount}
                        >
                        { renderAmounts() }
                        </Select>
                        {errors.amount?.type === 'required' && <FormHelperText>Amount is required</FormHelperText>}
                    </FormControl>  
                    {
                        !isDeployed && <Button variant='contained' fullWidth color='secondary' onClick={handleSubmit(scheduleScript)}>Deploy</Button>
                    }     
                    {
                        isDeployed && <Stack direction={'row'} gap={1} display={'flex'}>
                            <Button fullWidth variant='contained' color='secondary' onClick={() => pauseDeployment(data[0])}>Pause</Button>
                            <Button component={Link} to={`../../deployment/${data[0].id}`} variant='outlined' endIcon={<ArrowRightIcon/>}>View</Button>
                        </Stack>
                    }         
                </Stack>
            </CardContent>
        </Card>
    )
}

const Scheduler = ({ script, auth }) => {

    return <FirestoreCollection
        path={'deployed'}
        // sort="createdOn:desc"
        filter={['scriptId', '==', script.id]}
    >
        { ({error, isLoading, data}) => {

        if (error) {
            // return <Error error={error} />
        }

        if (isLoading) {
            return <Loading/>
        }
        return <SchedulerComponent script={script} data={data} />
        }}
    </FirestoreCollection>
}

export default Scheduler
import { createTheme } from '@mui/material/styles';
import typography from './typography';


const theme = createTheme({
    typography,
    palette: {
      primary: {
        main: '#1D2671'
      },
      secondary: {
        main: '#C33764'
      },
      successLight: {
        
      }
    },
    components: {
        MuiButton: {
        //   styleOverrides: {
        //     root: {
        //       borderRadius: '100px',
        //       textTransform: 'none'
        //     },
        //   },
        },
        MuiCard: {
            defaultProps: {
                elevation: 0
            },
            styleOverrides: {
            root: {
              borderWidth: 1,
              borderRadius: 8
            }
          }
        }
      }
});

  export default theme
import Firebase from 'firebase/compat/app'
import ReactGA from 'react-ga'
import slugify from 'slugify'

import { prepareDocForCreate } from './helpers/firestoreHelpers'
import updateScript from './updateScript'

const deployScript = (values, schedule) => {

  ReactGA.event({
    category: 'Deployed',
    action: 'Deploy script',
  })

  updateScript(values.id, {deployed: true})
  values.scriptId = values.id
  delete values.id
  delete values.createdOn
  delete values._likeCount
  values.running = false
  values.nextRun = Firebase.firestore.Timestamp.now()
  values.schedule = JSON.stringify(schedule)
  values.slug = slugify(values.name, {lower: true})
  values.published = false
  values.deployed = true

//   values.name = `${values.name}`
//   values.variables = '[]'
//   values.code = '// Dont fortget to wash your hands!'

  return Firebase.firestore()
    .collection('deployed')
    .add(prepareDocForCreate(values))
    // .then( () => values)
    // .catch( error => {
    //   alert(`Whoops, couldn't create the post: ${error.message}`)
    // })
}

export default deployScript
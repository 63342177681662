import Firebase from 'firebase/compat/app'
import ReactGA from 'react-ga'

const downloadResults = deploymentId => {
    ReactGA.event({
        category: 'Results',
        action: 'Download results',
    })

    return new Promise((resolve, reject) => {
        Firebase.firestore()
        .doc(`deployed/${deploymentId}`)
        .collection('results')
        .get().then(querySnapshot => {
            const docs = querySnapshot.docs.map(doc => doc.data())
            resolve(docs)
        }).catch(e => reject(e))
    })
}

export default downloadResults
// the main routes of our app are defined here using react-router
// https://reacttraining.com/react-router/web/example/basic

import React from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'

// import RequireAuth from './misc/RequireAuth'
import Script from './Script'
import PostList from './posts/PostList'
import PostNew from './posts/PostNew'
import Search from './search/Search'
import Account from './Account/Account'
import PostEdit from './posts/PostEdit'
import Post from './posts/Post'
import Error from './misc/Error'
import NewScript from './Script/NewScript'
import EditScript from './Script/EditScript'
import ViewScript from './Script/ViewScript'
import Scripts from './Scripts'
import Community from './Community'
import Deployed from './Deployed'
import ViewDeployment from './Deployment/ViewDeployment'
import Deployment from './Deployment'
import Results from './Results'
import Featured from './Featured'


const Router = () => (
  <Routes>
    <Route path="/" element={<Navigate to="/scripts" />} />
    <Route path="/scripts" element={<Scripts/>} />
    <Route path="/community" element={<Community/>}/>
    <Route path="/featured" element={<Featured/>}/>
    <Route path='/deployed' element={<Deployed/>}/>
    <Route path="/logs" element={<Results/>}/>
    <Route path="/script" element={<Script/>} >
      <Route path=":scriptId" element={<ViewScript/>} />
      <Route path="new" element={<NewScript/>} />
      <Route path=":scriptId/edit" element={<EditScript/>} />
    </Route>
    <Route path="/deployment" element={<Deployment/>}>
      <Route path=":deploymentId" element={<ViewDeployment/>}/>
    </Route>
    <Route path="/search" element={<Search/>} />
    <Route path="/account" element={<Account/>} />
    <Route element={<Error/>} />
  </Routes>
)

// scroll to top on route change
// https://github.com/ReactTraining/react-router/blob/master/packages/react-router-dom/docs/guides/scroll-restoration.md#scroll-to-top
class ScrollToTop extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0)
    }
  }
  render() {
    return null
  }
}

// Track Google Analytics page view for every route
// https://github.com/react-ga/react-ga/issues/122#issuecomment-319546248
const Analytics = ({location}) => {
  const page = location.pathname + location.search
  ReactGA.set({ page })
  ReactGA.pageview(page)
  return null
}

export default Router

import React from 'react'
import { CardActionArea, Card, CardContent, Box, Typography, Stack, Chip } from '@mui/material'
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import CloudSyncOutlinedIcon from '@mui/icons-material/CloudSyncOutlined';
import { Link } from 'react-router-dom'
import StyledChip from '../../components/StyledChip';

const DeploymentItem = ({ link, key, name, description, deployed, schedule, nextRun }) => {
  const { amount, unit } = JSON.parse(schedule)
  return (
    <CardActionArea sx={{borderRadius: 2}} to={link} component={Link}>
      <Card key={key} sx={{}}>
        <CardContent sx={{height: '300px', overflow: 'hidden', textOverflow: 'ellipsis', minWidth: '300px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
          <Box minHeight={100}>
            <Typography variant='subtitle1'>{name}</Typography>
            <Typography variant='p' color={'gray'}>{`${description.slice(0,120).trim()}${description.length > 120 ? '...' : ''}`}</Typography>
          </Box>
          
          <Box mt={2} sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
            <StyledChip icon={deployed ? <CloudUploadOutlinedIcon/> : <CloudOffIcon/>} color={deployed ? 'green' : 'red'} label={deployed ? 'Depolyed' : 'Paused' } />
            <StyledChip color={'blue'} icon={<TimerOutlinedIcon/>} label={`${amount} ${unit}`}/>
            <Box sx={{ flexBasis: '100%' }}>
              {
                deployed ? <StyledChip color={'purple'} icon={<CloudSyncOutlinedIcon/>} label={nextRun.toLocaleString()}/> : <Chip icon={<CloudSyncOutlinedIcon/>} label='Not Applicable'/>
              }
            </Box>
          </Box>
        </CardContent>
      </Card>
    </CardActionArea>
  )
}

export default DeploymentItem